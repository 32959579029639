import EndpointFactory from '~~/app/factories/EndpointFactory';
import UserEndpoint from '~~/app/auth/endpoints/UserEndpoint';
import { ModelType } from '~~/app/base/schemas/BaseSchema';

const { impersonateToken, setUserModel, quitImpersonation } = useAuthStore();

export default async () => {
    const endpoint = EndpointFactory.make(ModelType.USERS) as UserEndpoint;

    let authed;
    const regularUser = await endpoint.authed();
    if (!regularUser) return;
    authed = regularUser;

    if (impersonateToken) {
        const impersonateUser = await endpoint.authed(impersonateToken);
        if (!impersonateUser) {
            quitImpersonation();
        }
        if (impersonateUser) {
            authed = impersonateUser;
        }
    }

    setUserModel(authed);
};
