import { RouteLocationNormalized } from 'vue-router';
import { storeToRefs } from 'pinia';
import EndpointFactory from '~/app/factories/EndpointFactory';
import { ModelType } from '~/app/base/schemas/BaseSchema';

enum LoadingState {
    BASE = 'base',
    USER = 'user',
    TENANT = 'tenant',
    LOADED = 'loaded',
}

interface LoadingScreen {
    loading: Ref<LoadingState>;
    isLoading: Ref<boolean>;
    load: Promise<any> | null;
}

export default (route: RouteLocationNormalized) => {
    const { completed, markAsCompleted } = useSetupStore();
    const loading: Ref<LoadingState> = ref(completed ? LoadingState.LOADED : LoadingState.BASE);
    const isLoading = computed(() => loading.value !== LoadingState.LOADED);
    const { logout } = useAuthStore();
    const { token, impersonateToken, authed, tenant } = storeToRefs(useAuthStore());
    const { $trackEvent, $mixpanel } = useNuxtApp();

    const load = async () => {
        if (localStorage.getItem('locale')) {
            useSetLocale(localStorage.getItem('locale'));
        }

        await Promise.all([getData(), wait(500)]);

        if (authed.value && authed.value.locale) {
            useSetLocale(authed.value.locale);
        }

        markAsCompleted();
        loading.value = LoadingState.LOADED;
    };

    const getData = async () => {
        $trackEvent('loading');
        await useRouter().isReady();

        const loadingLogoutPage = route.path.startsWith('/auth/logout');
        if (loadingLogoutPage) {
            logout();
            await navigateTo('/auth/login');
            return;
        }

        if (!token.value && !impersonateToken.value) {
            const redirect = useGetGlobalRedirection(route);
            if (redirect) {
                await navigateTo(redirect);
            }
            return;
        }

        await useGetAuthed();

        if (!authed.value) {
            const redirect = useGetGlobalRedirection(route);
            if (redirect) {
                await navigateTo(redirect);
            }
            return;
        }

        if (route.path.startsWith('/auth/register/tenant')) {
            return;
        }

        // TODO
        const connectionEndpoint = EndpointFactory.make(ModelType.CONNECTIONS);

        const tenantRedirect = await useGetDefaultTenant();
        if (!tenant.value) {
            if (tenantRedirect) {
                await navigateTo(tenantRedirect);
                return;
            }

            const redirect = useGetGlobalRedirection(route);

            if (redirect) {
                await navigateTo(redirect);
                return;
            }
            return;
        }
        // init mix panel user

        connectionEndpoint.storeConnectionEntry();

        useSetupDefaultTenant();
        $mixpanel.identify(authed?.value.getId());
        $mixpanel.people.set({
            tenant_id: tenant?.value?.getId(),
            // device_type: 'web',
            subscription_plan: tenant?.value?.subscriptionPlan,
            locale: authed?.value.locale,
            region: tenant?.value.address?.province ?? null,
        });

        $trackEvent('loaded');

        const redirect = useGetGlobalRedirection(route);
        if (redirect) {
            await navigateTo(redirect);
        }
    };

    return {
        loading,
        isLoading,
        load: completed ? null : load,
    };
};
